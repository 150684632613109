import Headroom from '../plugins/headroom.js';
import Slick from '../plugins/slick.js';

(function($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Base = {
  // All pages
  common: {
    init: function() {
      /////
      // JavaScript to be fired on all pages
      /////

      // Sticky Header
      var myElement = document.querySelector(".global-header");
      var headroom  = new Headroom(myElement, {
        "offset": 150,
        "tolerance": 0
      });
      headroom.init();

      // Film Fitler Toggles
      $('.filter-toggle').click(function(e){
        e.preventDefault();
        if ($(this).attr('href') == '#all') {
          $('.film-grid').removeClass('hidden');
        } else {
          $('.film-grid').addClass('hidden');
          $('.film-grid[data-tax="' + $(this).attr('href') + '"]').removeClass('hidden');
        }
      });

      // // Pagination
      // $('#pagination').on('click', 'a', function(e){
      //   e.preventDefault();
      //   $(this).remove();
      //   $('#ajax-loader').load($(this).attr('href') + ' #ajax-posts>*', function(){
      //     $('#ajax-posts').append($('#ajax-loader').html());
      //   });
      //   $('#pagination').load($(this).attr('href') + ' #pagination>*');
      // });

      // Anchor
      $('a:not(.filter-toggle)').on('click', function(e){
        var $href = $(this).attr('href');
        if ($href.match('^#')) {
          e.preventDefault();
          $('html, body').stop().animate({
              scrollTop: $($href).offset().top
          });
        }
      });

      // Video popups
      // $('a').on('click', function(e){
      //   var $target = $(this).attr('href');
      //   if ($target.toLowerCase().indexOf("vimeo") >= 0) {
      //     e.preventDefault();
      //     var $n = $target.lastIndexOf('/');
      //     var $vid = $target.substring($n + 1);
      //     $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
      //     $('.video-popup-wrapper').addClass('active');
      //     $('body, html').addClass('body-noscroll');
      //   }
      //   if ($target.toLowerCase().indexOf("youtu") >= 0) {
      //     e.preventDefault();
      //     var $y = $target.lastIndexOf('=');
      //     var $yid = $target.substring($y + 1);
      //     $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
      //     $('.video-popup-wrapper').addClass('active');
      //     $('body, html').addClass('body-noscroll');
      //   }
      // });
      // $('.close-popup').on('click', function(){
      //   $('.video-popup-wrapper').removeClass('active');
      //   $('.popup-content').html('');
      //   $('body, html').removeClass('body-noscroll');
      // });

      // Mobile Nav
      $('#mobile-nav-toggle').on('click', function(){
        $('#mobile-navigation-wrapper').toggleClass('active');
        $('.mobile-nav-overlay').toggleClass('active');
        $(this).toggleClass('active');
      });
      $('.mobile-nav-overlay').click(function(){
        $('#mobile-navigation-wrapper').removeClass('active');
        $('.mobile-nav-overlay').removeClass('active');
        $('#mobile-nav-toggle').removeClass('active');
      });

      // Resize for Match-Height and Slick-Slider glitch fix
      this.scroll_actions;
      $(window).bind('load', function(){
        $(window).trigger('resize');
        setTimeout(function(){ $(window).trigger('resize'); }, 500);
        this.scroll_actions;
      });
      $(document).ready(this.scroll_actions);
      $(window).scroll(this.scroll_actions);
    },
    scroll_actions: function() {
      $('.loading').each(function(){
        $(this).addClass('loaded');
      });
      $('.animate').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          $this.addClass('visible');
        }
      });
      $('.animate-1').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 100);
        }
      });
      $('.animate-2').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 200);
        }
      });
      $('.animate-3').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 300);
        }
      });
      $('.animate-4').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 400);
        }
      });
      $('.animate-5').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 500);
        }
      });
      $('.animate-6').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 600);
        }
      });
      $('.animate-7').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 700);
        }
      });
      $('.animate-8').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 800);
        }
      });
      $('.animate-9').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 900);
        }
      });
      $('.animate-10').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1000);
        }
      });
      $('.animate-11').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1100);
        }
      });
      $('.animate-12').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1200);
        }
      });
      $('.animate-13').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1300);
        }
      });
      $('.animate-14').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1400);
        }
      });
      $('.animate-15').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1500);
        }
      });
      $('.animate-16').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1600);
        }
      });
      $('.animate-17').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1700);
        }
      });
      $('.animate-18').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1800);
        }
      });
      $('.animate-19').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 1900);
        }
      });
      $('.animate-20').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 2000);
        }
      });
      $('.animate-21').each(function(){
        var $this = $(this);
        if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
          setTimeout(function(){
            $this.addClass('visible');
          }, 2100);
        }
      });
    }
  },
  // Home page
  home: {
    init: function() {
      // JavaScript to be fired on the home page
      $('.hero-slider').slick({
        dots: false,
        infinite: true,
        speed: 1000,
		    fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        variableWidth: false,
        prevArrow: '<span class="prev">Previous</span>',
        nextArrow: '<span class="next">Next</span>',
        arrows: true,
        adaptiveHeight: true
      });
    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = Base;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
    });
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.